import React from 'react'
import { Link } from "gatsby"
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../components/cacola.css'

const IndexPage = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <SEO title="Home" keywords={[`home`]} />
      <div className="center">
        <h3>Hello</h3>
        <div className="creds">
          <span>Useff</span>
          <span>|</span>
          <span>Web</span>
          <span>|</span>
          <span>Blog</span>
        </div>
      </div>
      <div>
        <ul>
          {data.allMarkdownRemark.edges.map(({node}) => (<Link to={node.fields.slug} key={node.id} title={node.excerpt}>{node.frontmatter.title}</Link>))}
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
          }
          excerpt
          id
        }
      }
    }
  }
`

export default IndexPage
